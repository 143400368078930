<template>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="410px" v-model="dialog">
        <div class="popup__content">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title">Обновить платежные данные</h5>
            <v-form class="form"
                ref="form"
                v-model="valid"
                @submit.prevent="validate"
            >
                <div class="field">
                    <div class="control">
                        <v-text-field solo outlined flat
                            ref="card"
                            type="text"
                            label="Номер карты"
                            :rules="cartRules"
                        ></v-text-field>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <v-text-field solo outlined flat
                            type="text"
                            label="Владелец карты"
                        ></v-text-field>
                    </div>
                </div>
                <div class="fields">
                    <div class="field">
                        <div class="control">
                            <v-text-field solo outlined flat
                                ref="date"
                                type="text"
                                label="Срок"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <v-text-field solo outlined flat
                                ref="cvv"
                                type="text"
                                label="CVV-код"
                            ></v-text-field>
                        </div>
                    </div>
                </div>
                <span class="card__text">100% Secure SSL</span>
                <div class="field">
                    <div class="control">
                        <v-btn color="primary" block depressed>
                            Обновить данные карты
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </div>
    </v-dialog>
</template>

<script>
import Inputmask from 'inputmask'

export default {
    data() {
        return {
            dialog: false,
            valid: false,
            is_complete: true,
            cartRules: [
                // v => !!v || 'Некорректный номер телефона',
                // () => !!this.is_complete || 'noo',
            ],
        }
    },
    methods: {
        open() {
            this.dialog = true;
            this.$nextTick(() => {
                this.maskCard();
                this.maskDate();
                this.maskCvv();
            });
        },
        close() {
            this.dialog = false;
        },
        maskCard() {
            let inputmask = new Inputmask({
                mask: '9999 9999 9999 9999',
                showMaskOnHover: false,
                // oncomplete: () => {
                //     this.is_complete = true;
                //     this.$refs.form.validate();
                //     // console.log(this.is_complete)
                // },
                // onincomplete: () => {
                //     this.is_complete = false;
                // }
            });
            let input = this.$refs.card.$el.querySelector('input');
            inputmask.mask(input);
        },
        maskDate() {
            let inputmask = new Inputmask({
                mask: '99/99',
                showMaskOnHover: false,
            });
            let input = this.$refs.date.$el.querySelector('input');
            inputmask.mask(input);
        },
        maskCvv() {
            let inputmask = new Inputmask({
                mask: '999',
                showMaskOnHover: false,
            });
            let input = this.$refs.cvv.$el.querySelector('input');
            inputmask.mask(input);
        },
        validate() {
            this.$refs.form.validate();
            if (this.valid) {
                // this.$refs.PopupSuccess.open();
            } else {
                // this.$nextTick(() => {
                //     const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
                //     this.$vuetify.goTo(el, {
                //         offset: 100
                //     });
                //     return;
                // });
            }
        }
    }
}
</script>

<style lang="scss">
.card__text {
    display: block;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}
</style>

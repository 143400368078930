<template>
    <main>
        <template v-if="objects.list.length">
            <h1 class="heading">Billing</h1>

            <div class="billing">
                <div class="billing__objects">
                    <v-data-table class="table"
                        :headers="objects_s.headers"
                        :items="objects.list"
                        :disable-sort="true"
                        :mobile-breakpoint="1"
                        :hide-default-footer="objects.list.length<25"
                    >
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" :key="item.id">
                                    <td>{{ item.name }}</td>
                                    <td>
                                        <span v-if="+item.tariff_id === 1">FREE</span>
                                      <span v-if="+item.tariff_id === 3">PRO</span>
                                        <span class="link" style="margin-left: 10px" @click="$refs.PopupTariff.open(item)">Change</span>
                                    </td>
                                    <td>
                                      <span v-if="+item.tariff_id === 1">0 $</span>
                                      <span v-if="+item.tariff_id === 3">7 $</span></td>
                                </tr>
                                <tr>
                                    <td class="" colspan="2">Total month payment</td>
                                    <td class="billing__total"><span>{{ total }} $</span> per month</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>

            </div>

            <PopupCard ref="PopupCard" />
            <PopupTariff ref="PopupTariff"/>
        </template>
        <ObjectsEmpty title="Billing" v-else />
    </main>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex';
import titleMixin from "@/mixins/titleMixin";

import ObjectsEmpty from '@/components/ObjectsEmpty.vue'
import PopupCard from '@/components/PopupCard.vue'
import PopupTariff from '@/components/Popup/PopupTariff.vue'

export default {
    name: 'Billing',
    title() {
      return `Billing`;
    },
  mixins: [titleMixin],
    components: { ObjectsEmpty, PopupCard, PopupTariff },
    beforeRouteEnter (to, from, next) {

        if(!store.state.objects.list.length){

            store.dispatch('objects/getList')
                .then((data) => {
                    next();
                }).catch(() => {
                next();
            })
        }else{
            next();
        }
    },
    computed: {
        ...mapState(['objects']),
      total(){
          return this.objects.list.reduce(function(acc,item){
            if(+item.tariff_id === 2){
              return acc+=0
            }else if(+item.tariff_id === 3){
              return acc+=7
            }else{
              return acc+=0
            }
          },0)
      }
    },
  mounted(){

  },
    data: () => ({
        objects_s: {
            headers: [
                { text: 'Hotel', value: 'name' },
                { text: 'Tariff ', value: 'tariff' },
                { text: 'Payment', value: 'payment' },
            ],
            items: [
                { id: 1, name: 'Bristol Hotel Odessa', tariff: 'STANDART', payment: '$27/мес' },
                { id: 2, name: 'Grand Marine Hotel & SPA', tariff: 'FREE', payment: '$27/мес' },
            ],
        },
        orders: {
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Дата платежа', value: 'date' },
                { text: 'Сумма', value: 'sum' },
                { text: 'Статус', value: 'status' },
                { text: '', value: 'invoice', sortable: false },
            ],
            items: [
                { id: 44176, date: '29 декабря 2020', sum: '$54', status: 2, invoice: 'View invoice'},
                { id: 43423, date: '29 ноября 2020', sum: '$54', status: 1, invoice: 'View invoice'},
                { id: 54544, date: '29 октября 2020', sum: '$54', status: 1, invoice: 'View invoice'},
                { id: 67544, date: '29 сентября 2020', sum: '$54', status: 1, invoice: 'View invoice'},
                { id: 87544, date: '29 августа 2020', sum: '$54', status: 1, invoice: 'View invoice'},
            ],
        }
    }),
};
</script>

<style lang="scss">
.table__tr_hover-false {
    &:hover {
        background: transparent !important;
    }
}
.billing {
    @include row;
    align-items: flex-start;
}

.billing__objects {
    @include col(12);
    margin-bottom: 40px;
}

.billing__total {
    span {
        font-size: 24px;
        font-weight: bold;
    }
}

.card {
    @include col(4);
    @include box;
    margin-bottom: 40px;

}

.__PrivateStripeElement{
  width:calc(60% - 7px) !important;
  float:left !important;
}
.billing__orders {
    @include col(8);
}


.card__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
}

.card__heading {
    @include subtitle;
    margin: 0;
    padding: 30px;
    border-bottom: 1px solid #fafafa;
}

.card__icon {

}

.card__number {
    @include title;
    margin-bottom: 10px;
}

.card__date {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #7f8385;
}

.card__action {

}

</style>
